import React, { useMemo } from 'react';

import AccordionItem from '@rotaryintl/harris-accordion';
import Link from '@rotaryintl/harris-link';

import { getLanguageFromURL } from '@utils/query-params';

import { ChildReport } from '@typings/reports';

export const GenerateReportAccordion = ({
  items,
  riClubId,
  riDistrictId,
  individualNfId,
}: {
  items: ChildReport[];
  riClubId?: string;
  riDistrictId?: string;
  individualNfId?: string;
}) => {
  const getqueryParam = () => {
    if (riClubId) return `clubId=${riClubId}`;
    if (riDistrictId) return `districtId=${riDistrictId}`;
    if (individualNfId) return `individualNetforumId=${individualNfId}`;
    return '';
  };

  const queryParam = getqueryParam();
  const queryString = queryParam ? `?${queryParam}` : '';

  const accordionItems = useMemo(
    () =>
      items.map(report => {
        if (report.path.length > 0) {
          return {
            title: report.label,
            text: (
              <Link key={report.label} to={report.path}>
                {report.label}
              </Link>
            ),
          };
        }
        return {
          title: report.label,
          text: (
            <div className="flex flex-col gap-4">
              {report.subReports.map(subReport => (
                <Link
                  key={subReport.reportTitle}
                  className="w-fit"
                  to={`/${getLanguageFromURL()}/club-and-district-reports/${
                    report.oktaId
                  }/${subReport.reportTechnicalName}/${
                    report.dataSelector
                  }${queryString}`}
                >
                  {subReport.reportTitle}
                </Link>
              ))}
            </div>
          ),
        };
      }),
    [items, queryString]
  );

  return (
    <div>
      <AccordionItem items={accordionItems} />
    </div>
  );
};
